<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="!params.all">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            :items="vendors"
            item-text="supplier_name"
            item-value="id"
            v-model="params.vendorId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="statuses"
            v-model="params.status"
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="dropDownCurrency"
            v-model="params.currency"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1" class="pt-0">
          <v-checkbox v-model="params.all" label="All Vendor"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn @click="search" color="primary" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn @click="excel" color="success">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Invoice #</th>
              <th class="text-center">Invoice Date</th>
              <th class="text-center">PO#</th>
              <th class="text-center">Vendor Name</th>
              <th class="text-center">Due Date</th>
              <th class="text-center">Curr</th>
              <th class="text-center">Invoice Amount</th>
              <th class="text-center">PPN (in IDR)</th>
              <th class="text-center">Payment</th>
              <th class="text-center">Balance</th>
              <th class="text-center">Age fr Due</th>
              <th class="text-center">Remark</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in apDetail.items" :key="index">
              <td @click.stop="detail(item)">{{ item.invoiceNumber }}</td>
              <td>{{ item.invoiceDate }}</td>
              <td>{{ item.poNumber }}</td>
              <td>{{ item.vendor }}</td>
              <td>{{ item.invoiceDueDate }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ formatPrice(item.invoiceAmount) }}</td>
              <td>{{ formatPrice(item.ppn) }}</td>
              <td>{{ formatPrice(item.payment) }}</td>
              <td>{{ formatPrice(item.balance) }}</td>
              <td>{{ item.ageForDue }}</td>
              <td>{{ item.remark }}</td>
              <td>{{ item.status }}</td>
            </tr>
            <tr>
              <td colspan="9" class="text-right">
                Total Invoice {{ params.all ? "" : apDetail.vendorName }}
              </td>
              <td>{{ formatPrice(apDetail.total) }}</td>
            </tr>
            <tr>
              <td colspan="9" class="text-right">
                Total A/P: {{ params.all ? "" : apDetail.vendorName }}
              </td>
              <td>{{ formatPrice(apDetail.total) }}</td>
            </tr>
            <tr>
              <td colspan="9" class="text-right">Total A/P</td>
              <td>{{ formatPrice(apDetail.total) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <product-detail
        :dialog="dialog"
        :products="products"
        :currency="params.currency"
        @close="close"
      ></product-detail>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ProductDetail from "@/components/ProductDetail";
import fileDownload from "js-file-download";

export default {
  name: "report-ap-summary",
  components: {
    ProductDetail,
  },
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        vendorId: 0,
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
        status: "all",
        currency: "ALL",
        all: false,
      },
      statuses: ["all", "outstanding", "paid"],
      products: [],
      dialog: false,
    };
  },
  computed: {
    ...mapState("external", ["vendors"]),
    ...mapState("report", ["apDetail"]),
    ...mapState("currency", ["dropDownCurrency"]),
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/apDetail", this.params)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("external/getVendors"),
        await this.$store.dispatch("currency/getListCurrency"),
      ])
        .then(() => {
          this.$store.commit("currency/ADD_ALL", "ALL");
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async detail(item) {
      this.$store.commit("SET_LOADER", true);
      this.currency = item.currency;
      await this.$store
        .dispatch("report/productDetail", { id: item.id, type: item.type, transaction: "Purchase" })
        .then(response => {
          this.products = response.data;
          this.dialog = true;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/apExcel", this.params)
        .then(response => {
          fileDownload(
            response.data,
            `report-ap-${this.params.startDate}-${this.params.endDate}.xlsx`
          );
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
  beforeDestroy() {
    this.$store.commit("report/SET_AP", {});
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
